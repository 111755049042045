import * as React from "react"
import type { HeadFC } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import PageHead from "../components/page-head"
import TeamMember from "../components/team-member"
import Contact from "../components/contact"
import SectionHeadline from "../components/section-headline"
import { FadeInComponent, ZoomInComponent } from "../components/animate"

import "../styles/globals.scss"
import "../styles/home.scss"

const IndexPage = () => {
  return (
    <Layout className="home">
      <div className="section hero">
        <StaticImage
          src="../images/align-logo-blurred.svg"
          loading="eager"
          alt="Align"
          objectFit="contain"
          className="hero-logo" />        
        <h2>Beyond the obvious.</h2>
      </div>

      <div className="section manifest">
        <div className="flex">
          <div className="flex-cell">
            <h3>
              Manifesto
            </h3>
          </div>
          <div className="flex-cell">
            <ZoomInComponent>
              <p>
                The digital realm we create today is the reality we live in tomorrow. For that future reality to be built on an equitable and sustainable basis, the digital products and services we make must be designed to solve human challenges and built to move with the times.  
              </p>
              <p>
                That is why we founded Align. We are a team of intellectually curious dreamers and doers that thrive in complex environments and love tilting and turning things to find the true value. Our superpower is empathy and our undeniable advantage is that we’ve been through thick and thin together. 
              </p>
              <p>
                As a result, the work we produce with our clients is a direct reflection of our team: adaptable, deliberate, and more than the sum of its parts. 
              </p>
              <p>
                We always want to dig deeper, challenge the current state and go beyond the obvious when seeking the best solution for occurring problems.
              </p>
            </ZoomInComponent>
          </div>
        </div>
      </div>
      
      <div className="section company-principles">
        <StaticImage
          src="../images/more-stairs.png"
          loading="eager"
          alt="Stairs"
          className="centered-image" />

        <div className="flex">
          <div className="flex-cell">
            <SectionHeadline text="The values that guide our work" />
          </div>
          <div className="flex-cell">
            <div className="company-principle">
              <FadeInComponent>
                <h3>Be a kid.</h3>
                <p>
                  We are a bunch of easily excited people that enjoy cannonballing into the murky waters of the unknown. Asking the right questions and mapping out a world of opportunities is the most fun we can have at work. 
                </p>
              </FadeInComponent>
            </div>
            <div className="company-principle">
              <FadeInComponent delay={150}>
                <h3>Be an Adult.</h3>
                <p>
                  To us, black boxes and blind spots are the stepping stones to a game plan. We thrive in the most complex of domains, taking ownership of and great pride in our work. Our team’s unpretentious confidence derives from decades of experience in the industry.
                </p>
              </FadeInComponent>
            </div>
            <div className="company-principle">
              <FadeInComponent delay={300}>
                <h3>Be a Human.</h3>
                <p>
                  At the end of the day, the well-being of people, organizations, and the world matter to us most. We believe that positivity, respect, and active listening form the basis for success in business ventures, communication, and personal development.
                </p>
              </FadeInComponent>
            </div>
          </div>
        </div>
      </div>

      <div className="section team-members">
        <h3>Align Team</h3>

        <TeamMember
          name={"Sampsa Kuronen"}
          image={
            <StaticImage
              src={"../images/sampsa.jpg"}
              loading="eager"
              alt={"Sampsa"}
            />
          }
          blockImage={
            <StaticImage
              src={"../images/sampsa-blocks.jpg"}
              loading="lazy"
              alt={"Sampsa"} />
          }
          linkedInUrl={"https://www.linkedin.com/in/sampsa-kuronen"}
          asAKid={"At 11 years old, Sampsa built his first website - and hacked his primary school's computer network. Back then, there were no terms to describe such digital crimes, so the headmaster accused Sampsa and his friends of violating the secrecy of correspondence."}
          asAnAdult={"Sampsa is a versatile developer, team lead, startup advisor, and all-around gifted digital product builder. He enjoys throwing himself into whatever needs to be done for a digital service to have a sustainable technical base and the right product-market fit."}
          asAHuman={<React.Fragment>
            “An empath to the core” &mdash; <span className="quoter">Jari</span><br />
            “Skilled at multidimensional thinking” &mdash; <span className="quoter">Vessi</span><br />
            “Versatile, fair, and goodhearted” &mdash; <span className="quoter">Juho</span>
          </React.Fragment>}
        />

        <TeamMember
          name={"Jari Aarniala"}
          image={
            <StaticImage
              src={"../images/jari.jpg"}
              loading="eager"
              alt={"Jari"}
            />
          }
          blockImage={
            <StaticImage
              src={"../images/jari-blocks.jpg"}
              loading="lazy"
              alt={"Jari"} />
          }
          linkedInUrl={"https://www.linkedin.com/in/jari-aarniala"}
          asAKid={"Back in highschool, Jari founded Lamerfest, a LAN party that has been organized annually ever since. Later down the line, playing video games turned into playing the guitar: now, he spends his free time playing in various bands."}
          asAnAdult={"Jari is a technical problem solver with 25 years of experience in digital product development. An accomplished leader of technology projects with high quality standards, Jari enjoys building scalable and reliable services as well as tech teams that work well together, make pragmatic tech choices, and solve the right problems at hand."}
          asAHuman={<React.Fragment>
            “Makes things make sense” &mdash; <span className="quoter">Juho</span><br />
            “Sharp, analytical, and funny” &mdash; <span className="quoter">Vessi</span><br />
            “A great teacher” &mdash; <span className="quoter">Sampsa</span>
          </React.Fragment>}
          style={"reverse"}
        />

        <TeamMember
          name={"Vesa-Matti Mäkinen"}
          image={
            <StaticImage
              src={"../images/vessi.jpg"}
              loading="eager"
              alt={"Vessi"}
                      />            
          }
          blockImage={
            <StaticImage
              src={"../images/vessi-blocks.jpg"}
              loading="lazy"
              alt={"Vessi"} />
          }
          linkedInUrl={"https://www.linkedin.com/in/vesa-matti-m%C3%A4kinen-57666b122"}
          asAKid={"Audio technology has been an interest of Vessi's since he was a teenager. He is a self-taught music producer and audio engineer who enjoys every song with a long drum fill."}
          asAnAdult={"Vessi is an award-winning designer who likes getting to the bottom of things through observation and multi-disciplinary collaboration. Vessi’s background is in UX and product design, but 20 years of working with digital products and services have turned him into a skilled generalist who’ll solve any design dilemma with a notebook and a marker."}
          asAHuman={<React.Fragment>
            “Vision and rationale, come together” &mdash; <span className="quoter">Jari</span><br />
            “A trustworthy mood booster” &mdash; <span className="quoter">Juho</span><br />
            “Able to retain and structure an astonishing amount of information” &mdash; <span className="quoter">Sampsa</span>
          </React.Fragment>}
        />

        <TeamMember
          name={"Juho Koponen"}
          image={
            <StaticImage
              src={"../images/juho.jpg"}
              loading="eager"
              alt={"Juho"}
            />
          }
          blockImage={
            <StaticImage
              src={"../images/juho-blocks.jpg"}
              loading="lazy"
              alt={"Juho"} />
          }
          linkedInUrl={"https://www.linkedin.com/in/juhokoponen/"}
          asAKid={"Juho spent his childhood years living in various countries across the African continent. Having experienced a multitude of countries, cultures, and schooling systems at a young age, Juho has always felt at home in changing environments and uncommon contexts."}
          asAnAdult={"A highly skilled strategic thinker and operational generalist, Juho has spent the last two decades expertly navigating the intersection of business and technology. By combining his experience in software development and startup entrepreneurship, Juho approaches tech strategy in a way that sets teams and projects up for success."}
          asAHuman={<React.Fragment>
            “Gets stuff done” &mdash; <span className="quoter">Jari</span><br />
            “A great listener” &mdash; <span className="quoter">Vessi</span><br />
            “Highly empathetic and diplomatic” &mdash; <span className="quoter">Sampsa</span>
          </React.Fragment>}
          style={"reverse"}
        />
      </div>
      
      <div className="section footer">
        <div className="flex">
          <div className="flex-cell size-60 right-padded">
            <div>
              <h3>Diamonds are multifaceted, too.</h3>
              <p>We make the hypothetical concrete and the complex tangible. Let's talk more about what your business is about. </p>
            </div>
            <Contact />
          </div>
          <div className="flex-cell size-40">
            <StaticImage
              src="../images/diamond.png"
              loading="eager"
              alt="Diamond" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <PageHead />
