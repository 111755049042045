import * as React from "react"
import { AnimateIn } from "./animate"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

type TeamMemberProps = {
  name: string
  image: React.ReactElement
  blockImage: React.ReactElement
  linkedInUrl: string
  asAKid: string
  asAnAdult: string
  asAHuman: React.ReactElement
  style?: "reverse" | ""
}

const TeamMember = ({ name, image, blockImage, linkedInUrl, asAKid, asAnAdult, asAHuman, style }: TeamMemberProps) => (
  <div className={classNames("flex", "team-member", "centered", style)}>
    <div className="flex-cell portrait">
      <AnimateIn>
          {image}
      </AnimateIn>
      {blockImage}
    </div>
    <div className="flex-cell profile">
      <div className="name">
        {name}
        <a href={linkedInUrl} target="_blank">
          <StaticImage
            src={"../images/linkedin.png"}
            loading="eager"
            alt={"LinkedIn"}
            className={"linkedin"}
            objectFit={"contain"}

          />
        </a>
      </div>
      <h5>As a Kid</h5>
      <p>{asAKid}</p>
      <h5>As an adult</h5>
      <p>{asAnAdult}</p>
      <h5>As a human</h5>
      <p className="quote">{asAHuman}</p>
    </div>
  </div>
)

export default TeamMember
